import { useFetch } from '@/client.js'

class AppointmentsService {
  getByDate = (date) => useFetch(`appointments?date=${date}`).get().json()
  getByRange = (dateFrom, dateTo) =>
    useFetch(`appointments?date=${dateFrom}&dateTo=${dateTo}`).get().json()
  create = (appointment) => useFetch('appointments').post(appointment).json()
  createRecurring = (id, recurring) =>
    useFetch(`appointments/${id}/recurring`).post(recurring).json()
  update = (appointment) => useFetch(`appointments/${appointment.id}`).put(appointment)
  checkout = (appointment) => useFetch(`appointments/${appointment.id}`).patch(appointment)
  delete = (id) => useFetch(`appointments/${id}`).delete()
  getRecurring = (id) => useFetch(`appointments/recurring/${id}`).get().json()
  deleteRecurring = (id) => useFetch(`appointments/recurring/${id}`).delete()
  toggleFake = (id) => useFetch(`appointments/${id}/fake`).patch()
  updateService = (serviceId, appointmentId, startDate, employeeId) =>
    useFetch(`appointments/${appointmentId}/service/${serviceId}`).put({
      startDate: startDate,
      employeeId: employeeId
    })
}

export default new AppointmentsService()