import { differenceInYears, format } from 'date-fns'

export function currency(amount, decimals = true) {
  return new Intl.NumberFormat('el-GR', {
    style: 'currency',
    currency: 'EUR',
    maximumFractionDigits: decimals ? 2 : 0
  }).format(amount)
}

export function getMimeType(file, fallback = null) {
    const byteArray = (new Uint8Array(file)).subarray(0, 4);
    let header = '';
    for (let i = 0; i < byteArray.length; i++) {
        header += byteArray[i].toString(16);
    }
    switch (header) {
        case "89504e47":
            return "image/png";
        case "47494638":
            return "image/gif";
        case "ffd8ffe0":
        case "ffd8ffe1":
        case "ffd8ffe2":
        case "ffd8ffe3":
        case "ffd8ffe8":
            return "image/jpeg";
        default:
            return fallback;
    }
}

export function obfuscate (text, obfuscate = true) {
    return obfuscate ? text.replace(/\d/g, '*').padStart(8, '*') : text
}

export function displayDate (date, withTime = false) {
    if (!date) {
        return ''
    }
    return format(date, 'EEE, d MMM' + (withTime === true ? ' HH:mm' : ''))
}

export function displayTime(date) {
  if (!date) {
    return ''
  }
  return format(date, 'HH:mm')
}

export function displayLongDate (date, withTime = false, withWeekday = true) {
    if (!date) {
        return ''
    }
  return format(date, (withWeekday === true ? 'EEEE, ' : '') + 'd MMMM yyyy' + (withTime === true ? ' HH:mm' : ''))
}

export function isoDate (date, withTime = false) {
    if (!date) {
        return ''
    }
    return format(date, 'yyyy-MM-dd') + (withTime === true ? ' HH:mm' : '')
}

export function formatBirthdate (date) {
    if (!date) {
        return '-'
    }

    return `${format(date, 'd MMM yyyy')} (${differenceInYears(new Date(), date)})`
}

export function percent (amount, decimals = 2, convert = false) {
    if (convert === true) {
        amount /= 100
    }
    return new Intl.NumberFormat('el-GR', {
        style: 'percent',
        minimumFractionDigits: decimals ? decimals : 0,
        maximumFractionDigits: decimals ? decimals : 0
    }).format(amount)
}